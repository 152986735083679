import { Create, SimpleForm, TextInput } from 'react-admin';
import PermissionsInput from '../Permissions/PermissionsInput';


export const AdminCreate = () => {

    return (
        <Create title='Add Admin' redirect="list">
            <SimpleForm >
                <TextInput source="name" label="Please Enter Your Name" />
                <TextInput source="email" label="Please Enter Your Email" />
                <TextInput source="password" label="Please Enter Your Password" />
                <TextInput source="phone" label="Please Enter Your Phone" />
                <h5 style={{ marginBottom: 0, marginTop: 0 }}>Permissions:</h5>
                <PermissionsInput source="permissions" />
            </SimpleForm>
        </Create>
    );
}
