import { Label } from "@mui/icons-material";
import { Stack, Chip } from "@mui/material";
import { editableInputTypes } from "@testing-library/user-event/dist/utils";
import path, { basename } from "path";
import { Key, ReactElement, JSXElementConstructor, ReactFragment, ReactPortal } from "react";
import { ChipField, Datagrid, DeleteWithConfirmButton, EditButton, editFieldTypes, List, ReferenceArrayField, ReferenceField, ReferenceInput, SelectField,useListContext, SelectInput, ShowButton, SimpleForm, SimpleList, SingleFieldList, TextField,WrapperField, TextInput } from "react-admin";
import { pathToFileURL } from "url";
import { SystemWorkoutEdit } from "./SystemWorkoutEdit";
import { WorkoutPlanEdit } from "./WorkoutPlanEdit";

const EditProgramIcon = () => (
    <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1xkvp8t-MuiSvgIcon-root"
        focusable="false" viewBox="0 0 24 24" data-testid="CreateIcon">
        <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 
                0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z">
        </path>
    </svg>
);

const workoutFilters = [
    <TextInput label="Id" source="id" defaultValue="" />,
    <TextInput label="Workout Title" source="title" defaultValue="" />,
    <TextInput label="Schema has Workout Plan" source="schemaHasWorkoutPlan" defaultValue="" />,
    <TextInput label="Schema Workout Plan Type" source="schemaWorkoutPlanType" defaultValue="" />,
    <TextInput label="Programs" source="programs" defaultValue="" />,
];

export const SystemWorkoutsList = () => (
    <List filters={workoutFilters}>
        <Datagrid rowClick="show" >
            <TextField source="id" />
            <TextField label="Workout Title" source="workoutTitle" />
            <TextField label="Schema has Workout Plan" source="schemaHasWorkoutPlan" />
            <TextField label="Schema Workout Plan Type" source="schemaWorkoutPlanType" />
            <TextField label="Program" source="programs" />

            {/* <EditButton  label="Program" LinkComponent={SystemWorkoutEdit}/> */}
            <EditButton label="Schema" LinkComponent={WorkoutPlanEdit} />
            <ShowButton label="Program" LinkComponent={SystemWorkoutEdit} icon={<EditProgramIcon />} />

            <DeleteWithConfirmButton label="Delete"
                confirmTitle={"Delete This Workout"}
                confirmContent={"Are your sure you want to delete this record? You won't be able to retrieve it again."}
            />
        </Datagrid>
    </List>
);