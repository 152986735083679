import React, { useEffect, useState } from "react";
import { Datagrid, List, Loading, TextField, useDataProvider, useGetList } from "react-admin";
import { useParams, Link } from "react-router-dom";


export function MemberExecutionsList() {
  const { memberId } = useParams();
  const dataProvider = useDataProvider();
  const [memberName, setMemberName] = useState('');

  useEffect(() => {
    dataProvider.getOne('members', { id: memberId })
      .then(({ data }) => {
        setMemberName(data.name);
      })
      .catch(error => {
        console.error("Error fetching member data:", error);
      });
  }, [memberId, dataProvider]);


  return (
    <List resource="executions" filter={{ memberId }} title={`Members \\ ${memberName} \\ Executions`}>
    <Datagrid rowClick="show">
          <TextField source="id"/>
          <TextField label="Execution Title" source="executionTitle"/>        
          <TextField label="Execution Start Time" source="executionStartTime"/>              
          <TextField label="Execution Duration Time" source="executionDurationTime"/>        
      </Datagrid>
    </List>
  );
}
