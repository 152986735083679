import { Divider } from '@mui/material';
import { SimpleShowLayout, TextField } from 'react-admin';
import {StyledShow} from "../../themes/ocoach-default/StyledComponents";

export const ProgramShow = () => {
//     const { id } = useParams();
//     const redirect = useRedirect();
//    const { data, isLoading } = useGetOne(
//         'programs',
//         { id },

//         { onError: () => redirect('/error') }
//     );
//     if (isLoading) { return <Loading />; }
    return (

        <StyledShow>
            <SimpleShowLayout divider={<Divider flexItem />}>
                <TextField source="title" />
                <TextField source="description"  />
                <TextField source="createdDateTime"  />
                <TextField source="logo"  />
            </SimpleShowLayout>
        </StyledShow>

    );
}