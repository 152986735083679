import { useParams } from 'react-router-dom';
import { useGetOne, useRedirect, Title, Loading, useGetResourceLabel, GET_ONE } from 'react-admin';
import { Card, Divider, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Show, SimpleShowLayout, TextField, DateField, RichTextField } from 'react-admin';
import { StyledShow } from '../../themes/ocoach-default/StyledComponents';


interface CustomFieldProps {
    labels: string[];
    values: any[];
}

const CustomField: React.FC<CustomFieldProps> = ({ labels, values }) => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {labels.map((label, index) => (
            <div key={index} style={{ fontSize: '13px', width: `${100 / labels.length}%` }}>
                <div style={{ marginBottom: '5px' }}>
                    {label}
                </div>
                <div>
                    {values[index]}
                </div>
            </div>
        ))}
    </div>
);

export const MemberExecutionShow = () => {
    const { id } = useParams();
    const redirect = useRedirect();
    const { data, isLoading } = useGetOne(
        'executions',
        { id },

        { onError: () => redirect('/error') }
    );
    if (isLoading) { return <Loading />; }

    const executionContent = data?.executionRawContent ? JSON.parse(data.executionRawContent) : {};

    return (
        <StyledShow title={`Executions ${data.executionTitle ? `\\ ${data.executionTitle}` : "\\ Execution"}`}>
            <SimpleShowLayout divider={<Divider flexItem />}>
                <TextField source="id" label="Execution Id" />
                <TextField source="name" />
                <TextField source="executionTitle" label="Execution Title" />
                <CustomField labels={["Description"]} values={[executionContent.description]} />
                <CustomField labels={["Notes"]} values={[executionContent.notes]} />
                <CustomField labels={["StartDateTime", "EndDateTime"]} values={[executionContent.startDateTime, executionContent.endDateTime]} />
                <CustomField labels={["Type", "Status"]} values={[executionContent.type, executionContent.status]} />
                <CustomField labels={["EnableRepCounter", "EnableSplitAnnouncement", "EnableTimeLeftAnnouncement", "SecondsInterval"]} values={[executionContent.enableRepCounter, executionContent.enableSplitAnnouncement, executionContent.enableTimeLeftAnnouncement, executionContent.secondsInterval]} />
                <CustomField labels={["InitialSeconds", "LastSeconds", "Time"]} values={[executionContent.initialSeconds, executionContent.lastSeconds, executionContent.time]} />

                {executionContent && (
                    <>
                        {executionContent.executionSplits && (
                            <>
                                <h5 style={{ marginTop: '2px' }}>Execution Splits</h5>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Title</TableCell>
                                                <TableCell>Description</TableCell>
                                                <TableCell>StartDateTime</TableCell>
                                                <TableCell>EndDateTime</TableCell>
                                                <TableCell>Notes</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Length</TableCell>
                                                <TableCell>Reps</TableCell>
                                                <TableCell>Order</TableCell>
                                                <TableCell>Video Link</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {executionContent.executionSplits.map((executionSplit: any, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{executionSplit.title}</TableCell>
                                                    <TableCell>{executionSplit.description}</TableCell>
                                                    <TableCell>{executionSplit.startDateTime}</TableCell>
                                                    <TableCell>{executionSplit.endDateTime}</TableCell>
                                                    <TableCell>{executionSplit.notes}</TableCell>
                                                    <TableCell>{executionSplit.type}</TableCell>
                                                    <TableCell>{executionSplit.length}</TableCell>
                                                    <TableCell>{executionSplit.reps}</TableCell>
                                                    <TableCell>{executionSplit.order}</TableCell>
                                                    <TableCell>
                                                        {executionSplit.videoLink && (
                                                            <a href={executionSplit.videoLink} target="_blank" rel="noopener noreferrer">
                                                                {executionSplit.videoLink}
                                                            </a>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        )}
                    </>
                )}
            </SimpleShowLayout>
        </StyledShow>
    );
};
