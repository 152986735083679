import { Divider } from "@mui/material";
import * as React from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import Link from react-router-dom
import {
  useGetOne,
  Loading,
  TextField,
  SimpleShowLayout,
  ShowButton,
  Resource,
  Datagrid,
  List,
  Admin,
  useRecordContext,
  Button,
} from "react-admin";
import { StyledShow } from "../../themes/ocoach-default/StyledComponents";
import { MemberWorkoutsList } from "./MemberWorkoutsList"; // Import MemberWorkoutsList component
import { ocoachAuthProvider } from "../../providers/OCoachAuthProvider";
import { ocoachDataProvider } from "../../providers/OCoachDataProvier";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

const WorkoutLink = () => {
  const record = useRecordContext();
  if(!record) return null;
  return (
    <Link
      component={RouterLink}
      to={`/members/${record.id}/workouts`}
    >
      Show Workouts
    </Link>
  );
};

const ExecutionLink = () => {
  const record = useRecordContext();
  if(!record) return null;
  return (
    <Link
      component={RouterLink}
      to={`/members/${record.id}/executions`}
    >
      Show Executions
    </Link>
  );
};

const RecordTitle = () => {
  const record = useRecordContext();
  return <span>Members \ {record ? `${record.name}` : 'Member'}</span>;
};

export const MemberShow = () => {
  return (
    <>
      <StyledShow title={<RecordTitle />}>
        <SimpleShowLayout divider={<Divider flexItem />}>
          <TextField source="id" label="Member Id" />
          <TextField source="name" />
          <TextField source="email" />
          <TextField source="createdDateTime" label="Registration Date" />
          <TextField source="permissions" label="Permissions" />
          <TextField source="communities" label="Communities" />
          <WorkoutLink />
          <ExecutionLink />
        </SimpleShowLayout>
      </StyledShow>
    </>
  );
};
