import * as React from "react";
import { useParams } from 'react-router-dom';
import { useGetOne, useRedirect, Title, Loading } from 'react-admin';
import { Card, Divider, Stack, Typography } from '@mui/material';
import { Show, SimpleShowLayout, TextField, DateField, RichTextField } from 'react-admin';
import {StyledShow} from "../../themes/ocoach-default/StyledComponents";

export const AdminShow = () => {
    const { id } = useParams();
    const redirect = useRedirect();
    const { data, isLoading } = useGetOne(
        'adm',
        { id },

        { onError: () => redirect('/error') }
    );
    if (isLoading) { return <Loading />; }
    return (

        <StyledShow>
            <SimpleShowLayout divider={<Divider flexItem />}>
                <TextField source="name" />
                <TextField source="email"  />
                <TextField source="phone" label="Phone" />
                <TextField source="permissions" />
            </SimpleShowLayout>
        </StyledShow>
        // <div>
        //     <Title title="Admin Detail"/>
        //     <Card>
        //         <Stack spacing={1}>
        //         <div>
        //                 <Typography variant="caption" display="block">Name</Typography>
        //                 <Typography variant="body2">{data.name}</Typography>
        //             </div>
        //             <div>
        //                 <Typography variant="caption" display="block">Email</Typography>
        //                 <Typography variant="body2">{data.email}</Typography>
        //             </div>
        //             {/* <div>
        //                 <Typography variant="caption" display="block">Password</Typography>
        //                 <Typography variant="body2">{data.password}</Typography>
        //             </div> */}
        //             <div>
        //                 <Typography variant="caption" display="block">Phone</Typography>
        //                 <Typography variant="body2">{data.phone}</Typography>
        //             </div>
        //         </Stack>
        //     </Card>
        // </div>
    );
};