import { choices, FilterLiveSearch, Datagrid, DeleteWithConfirmButton, EditButton, List, SearchInput, SelectInput, ShowButton, TextField, TextInput, TextFieldProps } from "react-admin";
import { ExerciseEdit } from "./ExerciseEdit";
import { ExerciseShow } from "./ExerciseShow";

const exerciseFilters = [
    <TextInput label="Exercise Title" source="title" defaultValue="" />
];

export const ExerciseList = () => (
    <List filters={exerciseFilters} sort={{ field: 'createDateTimeDisplay', order: 'DESC' }} >
        <Datagrid rowClick="show" >
            <TextField source="id" />
            <TextField label="Exercise Title" source="title" />
            <TextField label="Exercise Description" source="description" />
            <TextField label="Exercise Video Link" source="videoLink" />
            <TextField label="Exercise Thumbnail Path" source="thumbnail" />
            <TextField label="Owner Name" source="ownerName" />
            <TextField label="Owner Website URL" source="ownerWebsiteURL" />
            <TextField label="Permission" source="permission" />
            <TextField label="Owner Email" source="ownerEmail" />

            <DeleteWithConfirmButton label="Delete"
                confirmTitle={"Delete Exercise"}
                confirmContent={"Are your sure you want to delete this exercise? You won't be able to retrieve it again."}
            />
            {/* <ShowButton label="Show" LinkComponent={ExerciseShow} /> */}
            <EditButton label="Edit" LinkComponent={ExerciseEdit} />
        </Datagrid>
    </List>
);