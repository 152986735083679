import { SimpleForm, Edit, TextInput, Toolbar, SaveButton, useEditController } from 'react-admin';
import PermissionsInput from '../Permissions/PermissionsInput';


const UserEditToolbar = (props:any) => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export const AdminEdit = (props:any) => {

    return (

        <Edit {...props} title='Edit Admin Details' style={{marginBottom: 20}}>
            <SimpleForm toolbar={<UserEditToolbar />}>

                <TextInput source="name" label="Please Enter Your Name"/>
                <TextInput source="email"  label="Please Enter Your Email"/>
                <TextInput source="password" label="Please Enter Your Password" />
                <TextInput source="phone" label="Please Enter Your Phone" />
                <h5 style={{marginBottom: 0, marginTop: 0}}>Permissions:</h5>
                <PermissionsInput source="permissions"/>

            </SimpleForm>
        </Edit>


    );
};