import { SimpleForm, Edit, Toolbar, SaveButton, TextInput } from 'react-admin';
import { TextField } from 'react-admin';

const ExerciseEditToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton />
        
    </Toolbar>
);
export const ExerciseEdit = (props:any) => {

    return (
        <Edit {...props} title='Edit Exercise'>
            <SimpleForm toolbar={<ExerciseEditToolbar />}  >
                <TextInput source="title" label="Please Enter Exercise Title" />
                <TextInput source="description" label="Please Enter Exercise Description"/>
                <TextInput source="videoLink" label="Please Enter Exercise VideoLink"  />
                <TextInput source="thumbnail" label="Please Enter Exercise Video Thumbnail Path"  />
            </SimpleForm>
        </Edit>
    );

};