import { useGetOne, useRedirect, Title, Loading, List, Datagrid, ListActions, TextInput } from 'react-admin';
import { Show, SimpleShowLayout, TextField, DateField, RichTextField } from 'react-admin';

const workoutFilters = [
 //   <TextInput label="Id" source="id" alwaysOn />,
    <TextInput label="WorkoutTitle" source="workoutTitle" defaultValue=""/>,
    <TextInput label="SchemaHasWorkoutPlan" source="schemaHasWorkoutPlan" defaultValue="NO-SchemaHasWorkoutPlan"/>,
    <TextInput label="SchemaHasWorkoutPlanType" source="schemaHasWorkoutPlanType" defaultValue="NO-SchemaHasWorkoutPlanType"/>,
];

export const WorkoutList = () => (
    <List filters={workoutFilters}>
        <Datagrid rowClick="show">
            <TextField source="id"/>
            <TextField label="Workout Title" source="workoutTitle"/>
            <TextField label="Schema has Workout Plan" source="schemaHasWorkoutPlan"/>
            <TextField label="Schema Workout Plan Type" source="schemaWorkoutPlanType"/>                
        </Datagrid>
    </List>
);

