import "./assets/styles/App.css";
import { Admin, CustomRoutes, Resource } from "react-admin";
import { MemberShow } from "./screens/members/MemberShow";
import { MemberList } from "./screens/members/MemberList";
import Dashboard from "./screens/Dashboard";
import { AdminIdentity } from "./identity/AdminIdentity";
import { ocoachAuthProvider } from "./providers/OCoachAuthProvider";
import { ocoachDataProvider } from "./providers/OCoachDataProvier";
import { ocoachTheme } from "./themes/ocoach-default/OCoachTheme";
import { WorkoutShow } from "./screens/Workout/WorkoutShow";
import { WorkoutList } from "./screens/Workout/WorkoutList";
import { AdminList } from "./screens/Admin/AdminList";
import { AdminShow } from "./screens/Admin/AdminShow";
import { AdminCreate } from "./screens/Admin/AdminCreate";
import { ProgramList } from "./screens/Programs/ProgramList";
import { ProgramCreate } from "./screens/Programs/ProgramCreate";
import { ProgramShow } from "./screens/Programs/ProgramShow";
import { ProgramEdit } from "./screens/Programs/ProgramEdit";
import { SystemWorkoutsList } from "./screens/System Workouts/SystemWorkoutsList";
import { SystemWorkoutEdit } from "./screens/System Workouts/SystemWorkoutEdit";
import { WorkoutPlanEdit } from "./screens/System Workouts/WorkoutPlanEdit";
import { WorkoutCreate } from "./screens/System Workouts/WorkoutCreate";
import { ExerciseList } from "./screens/Exercises/ExerciseList";
import { ExerciseEdit } from "./screens/Exercises/ExerciseEdit";
import { ExerciseCreate } from "./screens/Exercises/ExerciseCreate";
import { ExerciseShow } from "./screens/Exercises/ExerciseShow";
import { MemberAccountMerge } from "./screens/Account Merge/MemberAccountMerge";
import { SendNotification } from "./screens/Notifcation/SendNotification";
import { APIKeyList } from "./screens/APIKeys/APIKeyList";
import { APIKeyCreate } from "./screens/APIKeys/APIKeyCreate";
import { APIKeyShow } from "./screens/APIKeys/APIKeyShow";
import { MemberWorkoutsList } from "./screens/members/MemberWorkoutsList";
import { Route } from "react-router-dom";
import { MemberExecutionShow } from "./screens/members/MemberExecutionShow";
import { MemberExecutionsList } from "./screens/members/MemberExecutionsList";
import { AdminEdit } from "./screens/Admin/AdminEdit";

const App = () => {
  let x = new AdminIdentity();
  return (
    <Admin
      title="O'Coach Administration"
      theme={ocoachTheme}
      dashboard={Dashboard}
      authProvider={ocoachAuthProvider}
      dataProvider={ocoachDataProvider}
    >
      <Resource name="permissions" />

      <Resource name="members" show={MemberShow} list={MemberList}>
        <Route path=":memberId/workouts" element={<MemberWorkoutsList />} />
        <Route path=":memberId/executions" element={<MemberExecutionsList />} />
      </Resource>
      <Resource name="executions" show={MemberExecutionShow}/>
      <Resource name="workouts" show={WorkoutShow} list={WorkoutList} />
      <Resource
        name="adm"
        options={{ label: "Admins" }}
        show={AdminShow}
        list={AdminList}
        create={AdminCreate}
        edit={AdminEdit}
      />
      <Resource
        name="programs"
        options={{ label: "Programs" }}
        list={ProgramList}
        show={ProgramShow}
        edit={ProgramEdit}
        create={ProgramCreate}
      />
      <Resource
        name="systemWorkout"
        options={{ label: "System Workouts" }}
        list={SystemWorkoutsList}
        edit={WorkoutPlanEdit}
        show={SystemWorkoutEdit}
        create={WorkoutCreate}
      />
      <Resource
        name="exercise"
        options={{ label: "Exercises" }}
        list={ExerciseList}
        show={ExerciseShow}
        edit={ExerciseEdit}
        create={ExerciseCreate}
      />
      <Resource
        name="mergeAccount"
        options={{ label: "Merge Account" }}
        list={MemberAccountMerge}
      />
      <Resource
        name="notification"
        options={{ label: "Send Notification" }}
        list={SendNotification}
      />
      <Resource
        name="apiKey"
        options={{ label: "API Keys" }}
        show={APIKeyShow}
        list={APIKeyList}
        create={APIKeyCreate}
      />
    </Admin>
  );
};

export default App;
