import { defaultTheme, RaThemeOptions } from "react-admin";
import merge from "lodash/merge";
import createPalette from "@mui/material/styles/createPalette";
import { Theme, ThemeOptions } from "@mui/material/styles/createTheme";

const palette = createPalette(
  merge({}, defaultTheme.palette, {
    primary: {
      main: "#000000", // Not far from red
    },
    secondary: {
      main: "#977bb6", // Not far from green
    },
    text: {
      primary: "#666666",
    }
  })
);


const typographyKeys = {
  fontFamilySecondary: "Lato",
  fontFamily: 'Roboto',
  fontSize: 13, // Should be a number in pixels
  fontStyle: "normal",
  fontWeightLight: 100,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 500,
  color: palette.text.primary,
};

const typographyBase = {
  fontFamily: typographyKeys.fontFamily,
  fontSize: typographyKeys.fontSize,
  fontStyle: typographyKeys.fontStyle,
  color: typographyKeys.color,
};

const typographyHeader = {
  ...typographyBase,
  fontWeight: typographyKeys.fontWeightBold,
  fontFamily: typographyKeys.fontFamilySecondary, // Use a dedicated font for the header
};

const typographyBody = {
  ...typographyBase,
  fontWeight: typographyKeys.fontWeightRegular,
  fontFamily: typographyKeys.fontFamily,
};

const typography = {
  ...typographyBase,
  h1: {
    ...typographyHeader,
  },
  // ... Put other title styles below
  body1: {
    ...typographyBody,
  },
};

const components = {
  ...defaultTheme.components,
  Show: {
    styleOverrides: {
      root: {
        backgroundColor: 'yellow',
        '& .RaShow-main': {
          backgroundColor: 'red',
        },
      }
    }
  }
};


export const ocoachTheme = {
  ...defaultTheme,
  palette: palette,
  typography: typography,
};


// export const ocoachTheme = createTheme(
//   merge({}, defaultTheme, rawTheme)
// );