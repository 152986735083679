import { List, Datagrid, TextInput, EmailField, EditButton, Edit, ShowButton } from 'react-admin';
import { TextField } from 'react-admin';

const programFilters = [
    <TextInput label="Title" source="title" defaultValue="" />,
    
];


export const ProgramList = () => (
    <List filters={programFilters} >
        <Datagrid>
            <TextField source="id" />
            <TextField label="Program Title" source="title" />
            <TextField label="Description" source="description" />
            <TextField label="Logo" source="logo" />
            <TextField label="Created Date" source="createdDateTime" />
            
            <ShowButton />
            <EditButton />
            
            
        </Datagrid>
    </List>
);
