import * as React from "react";
import { Create, SimpleForm, } from "react-admin";
import { JsonInput } from "react-admin-json-view";


let def = {

    "workoutPlan": {
        "name": "",
        "title": "",
        "type": 0,
        "description": "",
        "createdDate": 637946872249430000,
        "initialSeconds": 0,
        "lastSeconds": 0,
        "secondsInterval": 0,
        "enableRepCounter": 0,
        "class": 0,
        "schedule": {
            "endDate": 0,
            "workoutTime": [
                0
            ],
            "days": [
                0
            ]
        },
        "splits": [
            {
                "title": "",
                "type": 0,
                "length": 0,
                "reps": 0,
                "order": 0,
                "description": "",
                "videoLink": "",
                "roundsGuid": "",
                "roundSplitGuid": ""
            }
        ],
        "specifications": {
            "images": {
                "cover": [
                    ""
                ],
                "thumbnail": "",
                "gallery": [
                    ""
                ]
            },
            "features": {
                "level": 0,
                "targetMuscle": " ",
                "equipments": ""
            },
            "benefits": [
                ""
            ],
            "instructions": [
                ""
            ],
            "media": [{
                "type": "",
                "uri": "",
                "title": ""
            }

            ]
        }
    },
    "shareId": "",
    "referrerName": "",
    "referrerNote": "",
    "workoutSchemaVersion": "",
    "workoutSchemaVersionMinimum": ""

}

export const WorkoutCreate = () => {

    return (
        <Create title='Create Workout' redirect="list" >

            <SimpleForm>

                <JsonInput source="workoutCreate" label="Workout Plan" jsonString={false}
                    defaultValue={def}
                    reactJsonOptions={{ collapsed: true, enableClipboard: false, displayDataTypes: true }} />

            </SimpleForm>

        </Create>
    );
}


