import { Create, SimpleForm, TextInput, } from "react-admin";

export const ExerciseCreate = () => {

    return (
        <Create title='Add Exercise' redirect="list" >

            <SimpleForm width={"30%"}>
                <TextInput source="title" label="Please Enter Exercise Title" fullWidth />
                <TextInput source="description" label="Please Enter Exercise Description" fullWidth />
                <TextInput source="videoLink" label="Please Enter Exercise Video Link" fullWidth />
                <TextInput source="thumbnail" label="Please Enter Exercise Video Thumbnail Path" fullWidth />
                <TextInput source="ownerName" label="Please Enter Exercise Owner Name" defaultValue={"O'Coach"} fullWidth />
                <TextInput source="ownerEmail" label="Please Enter Exercise Owner Email" fullWidth />
                <TextInput source="ownerWebsiteURL" label="Please Enter Owner's Website URL " fullWidth />
                <TextInput source="blogPageURL" label="Please Enter Blog Page URL " fullWidth />
            </SimpleForm>

        </Create>
    );
}


