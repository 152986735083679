import { List, Datagrid, TextInput, DeleteWithConfirmButton } from 'react-admin';
import { TextField} from 'react-admin';
import { PropTypes } from '@mui/material';
import { AdminIdentity } from '../../identity/AdminIdentity';

const apiFilters = [
    <TextInput label="Title" source="title" defaultValue="" />,
];

export const APIKeyList = () => (
    <List filters={apiFilters}>
        <Datagrid rowClick="show">
            <TextField label="Title" source="title" />         
            <TextField label="Key" source="key" />         
            <TextField label="Admin Name" source="adminName" />  
 
            <DeleteWithConfirmButton label="Revoke"
                confirmTitle={"Delete This API Key"}
                confirmContent={"Are your sure you want to delete this record? You won't be able to retrieve it again."}
            />     
        
        </Datagrid>
    </List>
);
