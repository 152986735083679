import { request } from 'http';
import React from 'react';
import { AdminIdentity } from '../identity/AdminIdentity';
import { AuthHeader } from '../identity/AuthHeader';
import { encode } from 'js-base64';
import { APIKeyList } from '../screens/APIKeys/APIKeyList';

export const ocoachAuthProvider = {
    // authentication
    login: ({ username, password }: any) => {
        const request = new Request(
            process.env.REACT_APP_API_URL + '/adm/login',
            {
                method: 'POST',
                body: JSON.stringify({ email: username, password }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            }
        );
        return fetch(request)
            .then(async (response) => {
                if (response.status < 200 || response.status >= 300) {
                    const error = await response.json();
                    throw new Error(error && error.Error ? error.Error : response.statusText);
                }
                return response.json();
            })
            .then((auth) => {
                localStorage.setItem(
                    'auth',
                    JSON.stringify(auth)
                );
            })
            .catch((error) => {
                throw new Error(error && error.message && error.message.length > 0 ? error : 'Network error');
            });
    },
    /**
     * Rach time the dataProvider returns an error, react-admin calls the authProvider.checkError() method. If it returns a rejected promise, react-admin calls the authProvider.logout() method immediately, and asks the user to log in again.
     * NOTE:
     * We return 401 from API (dataprovider) if auth header is missing/invalid.
     */
    checkError: (error: any) => {
        console.log("CheckError");
        const status = error.status;
        if (status === 401 || status === 403) {
            console.log("CheckError::401 or 403");
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },

    /**
     * Each time the user navigates to a list, edit, create or show page, react-admin calls the authProvider.checkAuth() method. If this method returns a rejected Promise, react-admin calls authProvider.logout() and redirects the user to the login page.
     * NOTE:
     * It is only checking for availability of cookie/localstorage on client side. If it exists, then it doesn't guarantee if it is valid or not.
     */
    checkAuth: () =>
        localStorage.getItem('auth')
            ? Promise.resolve()
            : Promise.reject({ message: 'login required' }),
    logout: () => {
        try {
            let auth = localStorage.getItem('auth') || '';
            let authHeader = new AuthHeader();
            let strAuthHeader = "";
            if (auth && auth.length > 0) {
                const identity: AdminIdentity = JSON.parse(auth);
                console.log("identity" + JSON.stringify(identity));
                authHeader.id = identity && identity.id ? identity.id : "";
                authHeader.sessionGuid = identity && identity.sessionGuid ? identity.sessionGuid : "";
                strAuthHeader = encode(JSON.stringify(authHeader))
                const request = new Request(
                    process.env.REACT_APP_API_URL + '/adm/logout',
                    {
                        method: 'POST',
                        body: JSON.stringify(identity),
                        headers: {
                            "X-AUTH-TOKEN": `Bearer ${strAuthHeader}`,
                            'Content-Type': 'application/json',
                        }
                    }
                );
                console.log("auth provider",JSON.stringify(identity));
                fetch(request)
                    .then(() => {
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            }
        }
        catch (error) {
            console.log(error)
        }
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    getIdentity: () => {
        try {
            const identity: AdminIdentity = JSON.parse(localStorage.getItem('auth') || '');
            return Promise.resolve(new AdminIdentity());
        } catch (error) {
            return Promise.reject(error);
        }
    },
  
    getPermissions: (params: any) => Promise.resolve(),
};