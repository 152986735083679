import { SimpleForm, Edit, TextInput, Toolbar, SaveButton } from 'react-admin';


const UserEditToolbar = (props:any) => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export const ProgramEdit = (props:any) => {

    return (

        <Edit {...props} title='Edit Program' >
            <SimpleForm toolbar={<UserEditToolbar />}>
                <TextInput source="title" label="Please Enter Program Title" />
                <TextInput source="logo" label="Please Enter Program Logo" />
                <TextInput source="description" label="Please Enter Program Description" />

            </SimpleForm>
        </Edit>


    );
};