import React from 'react';
import logo from '../assets/images/logo.svg';
import '../assets/styles/App.css';
import { Card, CardContent, CardHeader } from '@mui/material';

const Dashboard = () => (
    <Card>
        <CardHeader title="O'Coach Administration" />
        <CardContent>What are you going to do today to make the world a fitter, better place?</CardContent>
    </Card>
);
export default Dashboard;