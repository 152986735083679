import { makeStyles, styled } from "@mui/material/styles";
import { spacing } from "@mui/system";
import { defaultTheme, Show } from "react-admin";
//import defaultMuiTheme from "@material-ui/core/styles/defaultTheme";

export const StyledShow = styled(Show)({
    // backgroundColor: "Lavender",
    // "& .RaShow-card": {
    //     backgroundColor: "MistyRose",
    // }
    marginBottom: '15px'
});
