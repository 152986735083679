import { Create, SimpleForm, TextInput } from 'react-admin';

export const MemberAccountMerge = () => {

    return (
        <Create title='Merge Account' redirect="list">
            <SimpleForm >
                <TextInput source="firstEmail" label="Please Enter Your Old Email" />
                <TextInput source="secondEmail" label="Please Enter Your New Email" />
            </SimpleForm>
        </Create>
    );

}