import React from 'react';
import simpleRestProvider from 'ra-data-simple-rest';
import { fetchUtils, Options } from 'react-admin';
import { AdminIdentity } from '../identity/AdminIdentity';
import { AuthHeader } from '../identity/AuthHeader';
import { encode } from 'js-base64';

const fetchJson = (url: string, options: any = {}) => {
    let authHeaderToken = "";

    try {
        let authHeader = new AuthHeader();
        const identity: AdminIdentity = JSON.parse(localStorage.getItem('auth') || '');
        console.log("identity" + JSON.stringify(identity));
        authHeader.id = identity && identity.id ? identity.id : "";
        authHeader.sessionGuid = identity && identity.sessionGuid ? identity.sessionGuid : "";
        authHeaderToken = encode(JSON.stringify(authHeader))
        console.log('authHeaderToken',authHeaderToken);
    }
    catch (error) {

    }
    options.user = {
        authenticated: true,
        // use the token from local storage
        token: authHeaderToken,
    };
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // add your own headers here
    options.headers.set('X-AUTH-TOKEN', `Bearer ${authHeaderToken}`);

    // console.log("authHeaderToken: " + JSON.stringify(authHeaderToken));
    // console.log("authHeader: " + JSON.stringify(options));
    // console.log("url: " + url);
    return fetchUtils.fetchJson(url, options);
};

export const ocoachDataProvider = simpleRestProvider(process.env.REACT_APP_API_URL!, fetchJson);
