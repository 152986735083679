import React, { useCallback, useEffect, useState } from 'react';
import { useDataProvider, useInput } from 'react-admin';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

interface PermissionsInputProps {
    source: string;
}

interface Permission {
    id: string;
    module: string;
    action: string;
    displayName: string;
    description: string;
    selected: boolean;
}

const PermissionsInput = ({ source }: PermissionsInputProps) => {
    const { field } = useInput({ source });
    const { value, onChange } = field;
    const [permissions, setPermissions] = useState<Permission[]>([]);
    const dataProvider = useDataProvider();

    useEffect(() => {
        dataProvider.getList("permissions", {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'id', order: 'ASC' },
            filter: {},
        }).then(({ data }) => {
            const updatedPermissions = (data as Permission[]).map(permission => ({
                ...permission,
                selected: value ? value.some(
                    (v: any) => v === permission.id) : false
            }));
            setPermissions(updatedPermissions);
        });
    }, []);

    const handleCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;
        const updatedPermissions = permissions.map(p => {
            if (p.id == value) {
                return {
                    ...p,
                    selected: checked
                };
            }
            return p;
        });
        setPermissions(updatedPermissions);
        const selectedPermissions = updatedPermissions
            .filter(p => p.selected)
            .map(p => p.id);
        onChange(selectedPermissions);
    }, [permissions]);


    return (
        <FormGroup>
            {permissions.map(permission => {
                return (
                    <FormControlLabel
                        key={permission.id}
                        control={
                            <Checkbox
                                name={"chkPermissions"}
                                value={permission.id}
                                onChange={handleCheckboxChange}
                                checked={permission.selected}
                            />
                        }
                        label={`${permission.displayName}`}
                    />
                );
            })}
        </FormGroup>
    );
};

export default PermissionsInput;
