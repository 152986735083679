import React, { useEffect, useState } from "react";
import { Datagrid, List, Loading, TextField, useDataProvider, useGetList, useRecordContext } from "react-admin";
import { useParams, Link } from "react-router-dom";


export function MemberWorkoutsList() {
  const { memberId } = useParams();
  const dataProvider = useDataProvider();
  const [memberName, setMemberName] = useState('');

  useEffect(() => {
    dataProvider.getOne('members', { id: memberId })
      .then(({ data }) => {
        setMemberName(data.name);
      })
      .catch(error => {
        console.error("Error fetching member data:", error);
      });
  }, [memberId, dataProvider]);


  return (
      <List resource="workouts" filter={{ memberId }} title={`Members \\ ${memberName} \\ Workouts`}>
      <Datagrid rowClick="show">
            <TextField source="id"/>
            <TextField label="Workout Title" source="workoutTitle"/>
            <TextField label="Schema has Workout Plan" source="schemaHasWorkoutPlan"/>
            <TextField label="Schema Workout Plan Type" source="schemaWorkoutPlanType"/>                
        </Datagrid>
      </List>
  );
}
