import { Create, SimpleForm, TextInput } from 'react-admin';


export const ProgramCreate = () => {

        return (
        <Create title='Add Program' redirect="list" >
                <SimpleForm >
                <TextInput source="title" label="Please Enter Program Title"/>
                <TextInput source="logo" label="Please Enter Program Logo"/>
                <TextInput source="description" label="Please Enter Program Description"/>
               
            </SimpleForm>
        </Create>
    );

}