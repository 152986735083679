
import { SimpleForm, Edit, ReferenceInput, Toolbar, SaveButton, SelectArrayInput, SelectInput } from 'react-admin';
import { TextField } from 'react-admin';


const UserEditToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton />

    </Toolbar>
);


export const SystemWorkoutEdit = (props: any) => {
 
    return (

        <Edit {...props} title='Edit System Workout' actions={false}>
            <SimpleForm toolbar={<UserEditToolbar />}  >
                {/* <span>Workout Title</span> */}
                <TextField label="Workout Title" source="workoutTitle" fullWidth />
                <ReferenceInput source="programId" reference="programs"  >
                    {/* <SelectInput optionText="title" optionValue="id" resettable /> */}
                    <SelectArrayInput optionText="title" source="name" label={"Program"} optionValue="id" />
                </ReferenceInput>

            </SimpleForm>
        </Edit>
    );

};