import { Create, SimpleForm, TextInput, useRedirect, useGetOne } from 'react-admin';


export const APIKeyCreate = () => {

    return (
        <Create title='Create New Api Keys' redirect="list">
            <SimpleForm>
                <TextInput source="title" label="Please Enter Title" />
            </SimpleForm>
        </Create>
    );

}
