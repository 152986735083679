import { Divider } from '@mui/material';
import { RichTextField, SimpleShowLayout, TextField } from 'react-admin';
import { StyledShow } from "../../themes/ocoach-default/StyledComponents";

export const ExerciseShow = () => {
    return (

        <StyledShow>
            <SimpleShowLayout divider={<Divider flexItem />}>
                <TextField source="title" />
                <TextField source="description" />
                <TextField source="videoLink" />
                <TextField source="thumbnail" />
                <TextField source="ownerName" />
                <TextField source="ownerWebsiteURL" />
                <TextField source="permission" />
                <TextField source="ownerEmail" />
                <TextField source="createdDateTime" />
            </SimpleShowLayout>
        </StyledShow>

    );
}