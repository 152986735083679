import { AutocompleteArrayInput, Create, Loading, ReferenceArrayInput, ReferenceInput, SelectArrayInput, SelectInput, SimpleForm, TextInput, useGetList, useGetOne, useRedirect } from 'react-admin';

export const SendNotification = () => {
    const redirect = useRedirect();
    const { data, total, isLoading, error, refetch } = useGetList(
        "notification",
        {
        }
    );
    if (isLoading) { return <Loading />; }
    return (
        <Create title='Send Notification' redirect="list">
            <SimpleForm >
                {/* <ReferenceInput source="memberId" reference="notification"
                > */}
                    <AutocompleteArrayInput
                        optionText="name"
                        label={"Member Name"}
                        optionValue="id"
                        source={"memberId"}
                        choices={data?.sort()}
                    />
                    {/* {/* <SelectArrayInput optionText="name" source="name" label={"MemberName"} optionValue="id" /> */}
                {/* </ReferenceInput> */}
                <TextInput source="title" label="Notification Title" />
                <TextInput source="text" label="Notification Text" />
            </SimpleForm>
        </Create>
    );

}

