import * as React from "react";
import { useParams } from 'react-router-dom';
import { useGetOne, useRedirect, Loading } from 'react-admin';
import { Divider} from '@mui/material';
import { SimpleShowLayout, TextField } from 'react-admin';
import {StyledShow} from "../../themes/ocoach-default/StyledComponents";

export const APIKeyShow = () => {
    const { id } = useParams();
    const redirect = useRedirect();
    const { data, isLoading } = useGetOne(
        'apiKey',
        { id },

        { onError: () => redirect('/error') }
    );
    if (isLoading) { return <Loading />; }
    return (

        <StyledShow>
            <SimpleShowLayout divider={<Divider flexItem />}>
                <TextField source="title" />
                <TextField source="key" />
                <TextField source="adminName" />
            </SimpleShowLayout>
        </StyledShow>
      
    );
};