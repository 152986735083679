import { List, Datagrid, TextInput, EmailField, EditButton } from 'react-admin';
import { TextField} from 'react-admin';

const adminFilters = [
    <TextInput label="Name" source="name" defaultValue="" />,
    <TextInput label="Email" source="email" defaultValue="" />,
    <TextInput label="Phone" source="phone" defaultValue="" />,
];

export const AdminList = () => (
    <List filters={adminFilters}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField label="Full Name" source="name" />
            <EmailField label="Email" source="email" />
            <TextField label="Password" source="password" />
            <TextField label="Phone" source="phone" />
            {/* <TextField label="Permissions" source="permissions" /> */}
            <EditButton />
        </Datagrid>
    </List>
);
