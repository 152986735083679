import { useParams } from 'react-router-dom';
import { useGetOne, useRedirect, Title, Loading, useGetResourceLabel, GET_ONE, useRecordContext } from 'react-admin';
import { Card, Divider, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Show, SimpleShowLayout, TextField, DateField, RichTextField } from 'react-admin';
import { StyledShow } from '../../themes/ocoach-default/StyledComponents';


interface CustomFieldProps {
    labels: string[];
    values: any[];
}

const CustomField: React.FC<CustomFieldProps> = ({ labels, values }) => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {labels.map((label, index) => (
            <div key={index} style={{fontSize: '13px', width: `${100/labels.length}%` }}>
                <div style={{marginBottom: '5px'}}>
                    {label}
                </div>
                <div>
                    {values[index]}
                </div>
            </div>
        ))}
    </div>
);

export const WorkoutShow: React.FC = () => {
    const { id } = useParams();
    const redirect = useRedirect();
    const { data, isLoading } = useGetOne(
        'workouts',
        { id },

        { onError: () => redirect('/error') }
    );
    if (isLoading) { return <Loading />; }

    const workoutContent = data?.workoutRawContent ? JSON.parse(data.workoutRawContent) : {};

    return (
        <StyledShow title={`Workouts ${data.workoutTitle ? `\\ ${data.workoutTitle}` : "\\ Workout"}`}>
            <SimpleShowLayout divider={<Divider flexItem />}>
                <TextField source="id" label="Workout Id" />
                <TextField source="name" />
                <TextField source="workoutTitle" label="Workout Title" />
                <CustomField labels={["Description"]} values={[workoutContent.description]} />
                <CustomField labels={["Class", "Type"]} values={[workoutContent.class, workoutContent.type]} />
                <CustomField labels={["EnableRepCounter", "EnableSplitAnnouncement", "EnableTimeLeftAnnouncement", "SecondsInterval"]} values={[workoutContent.enableRepCounter, workoutContent.enableSplitAnnouncement, workoutContent.enableTimeLeftAnnouncement, workoutContent.secondsInterval]} />
                <CustomField labels={["InitialSeconds", "LastSeconds"]} values={[workoutContent.initialSeconds, workoutContent.lastSeconds]} />

                {workoutContent && (
                    <>
                        {workoutContent.specifications && (
                            <>
                                <h5 style={{ marginTop: '2px' }}>Specifications</h5>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Cover</TableCell>
                                                <TableCell>Thumbnail</TableCell>
                                                <TableCell>Gallery</TableCell>
                                                <TableCell>Level</TableCell>
                                                <TableCell>Target Muscle</TableCell>
                                                <TableCell>Equipments</TableCell>
                                                <TableCell>Benefits</TableCell>
                                                <TableCell>Instructions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>{workoutContent.specifications.images.cover.join(', ')}</TableCell>
                                                <TableCell>{workoutContent.specifications.images.thumbnail}</TableCell>
                                                <TableCell>{workoutContent.specifications.images.gallery.join(', ')}</TableCell>
                                                <TableCell>{workoutContent.specifications.features.level}</TableCell>
                                                <TableCell>{workoutContent.specifications.features.targetMuscle}</TableCell>
                                                <TableCell>{workoutContent.specifications.features.equipments}</TableCell>
                                                <TableCell>{workoutContent.specifications.benefits.join(', ')}</TableCell>
                                                <TableCell>{workoutContent.specifications.instructions.join(', ')}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        )}

                        {workoutContent.schedule && (
                            <>
                                <h5>Schedule</h5>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>End Date</TableCell>
                                                <TableCell>Workout Time</TableCell>
                                                <TableCell>Days</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>{workoutContent.schedule.endDate}</TableCell>
                                                <TableCell>{workoutContent.schedule.workoutTime.join(', ')}</TableCell>
                                                <TableCell>{workoutContent.schedule.days.join(', ')}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        )}

                        {workoutContent.splits && (
                            <>
                                <h5>Splits</h5>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Title</TableCell>
                                                <TableCell>Description</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Length</TableCell>
                                                <TableCell>Order</TableCell>
                                                <TableCell>Reps</TableCell>
                                                <TableCell>Video Link</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {workoutContent.splits.map((split: any, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{split.title}</TableCell>
                                                    <TableCell>{split.description}</TableCell>
                                                    <TableCell>{split.type}</TableCell>
                                                    <TableCell>{split.length}</TableCell>
                                                    <TableCell>{split.order}</TableCell>
                                                    <TableCell>{split.reps}</TableCell>
                                                    <TableCell>
                                                        {split.videoLink && (
                                                            <a href={split.videoLink} target="_blank" rel="noopener noreferrer">
                                                                {split.videoLink}
                                                            </a>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        )}
                    </>
                )}
            </SimpleShowLayout>
        </StyledShow>
    );
};
